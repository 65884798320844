<template>
  <v-card
    flat
  >
    <v-card-text>
      <v-container>
        <v-form
          ref="form"
        >
          <v-row>

            <v-col cols="6">
              <v-menu
                v-model="purchaseDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.purchaseDate"
                    :label="$vuetify.lang.t('$vuetify.equipment.purchaseDate')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.purchaseDate"
                  @input="purchaseDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6">
              <v-menu
                v-model="storeDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.storeDate"
                    :label="$vuetify.lang.t('$vuetify.equipment.storeDate')"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.storeDate"
                  @input="storeDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="6"
            >
              <v-text-field
                v-model="form.proNumber"
                :label="$vuetify.lang.t('$vuetify.equipment.proNumber')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="6"
            >
              <v-text-field
                v-model="form.deviceId"
                :label="$vuetify.lang.t('$vuetify.equipment.deviceId')"
                :rules="rules.deviceId"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        birthdayPickerMenu: false,
        purchaseDateMenu: false,
        storeDateMenu: false,
        form: {
          emId: null,
          purchaseDate: this.$moment().format('YYYY-MM-DD'),
          storeDate: this.$moment().format('YYYY-MM-DD'),
          proNumber: null,
          deviceId: null
        },
        rules: {
          deviceId: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.equipment.deviceId')])
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        departments: 'department/departments'
      }),
    },
    created () {
      this.departmentQueryAll()
    },
    methods: {
      ...mapActions ({
        departmentQueryAll: 'department/queryAll'
      }),
      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
          this.form.credentialType = 'I'
          this.form.depId = this.editItem.department.id
        } else {
          this.resetFrom()
        }
      },
      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.$refs.form.resetValidation()

      },
      handleCancel() {
        this.$emit('cancel', true)
      },
      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          if (this.editStatus) {
            data.id = this.form.id
            this.$emit('update', data);
          } else {
            this.$emit('submit', data);
          }
        }
      }
    }
  }
</script>
